import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon100years from "../components/icons/100years"
import IconWorld from "../components/icons/world"
import IconLocal from "../components/icons/local"
import IconMembers from "../components/icons/members"

const DitIsJCI = () => (
  <Layout>
    <SEO title="Dit is jci dendermonde" />
    <div class="pt-40">
      <div class="container mt-4 px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <h2>Over ons</h2>
        <p>JCI staat voor Junior Chamber International en is een vereniging waarin jonge mensen de kans krijgen om in initiatieven te stappen of zelf initiatieven op te zetten waarbij ze zich kunnen ontwikkelen tot een betere (team)leider, ondernemer of medewerker. Daarbij wordt vooral zin voor eigen initiatief gestimuleerd, worden mensen uitgedaagd om verantwoordelijkheid te nemen door buiten hun comfortzone te treden, en ontdekken mensen dat ze heel wat meer kunnen dan wat ze op het eerste zicht zouden denken!</p>
        <h3>Over Junior Chamber International</h3>
        <p>Onze werking situeert zich bewust binnen vier pijlers. 
          Zo onderscheiden we ons van traditionele business- of serviceclubs en zijn we in staat onze leden een oneindig brede waaier aan projecten, 
          activiteiten en mogelijkheden aan te bieden. JCI, dat is voor elk wat wils.</p>
        <div class="grid grid-cols-4 gap-4">
            <div class="col-span-4 md:col-span-1">
                <IconMembers></IconMembers>
                <p>+200.000 leden wereldwijd +/- 1000 in Vlaanderen</p>
            </div>
            <div class="col-span-4 md:col-span-1">
                <IconWorld></IconWorld>
                <p>actief in +100 landen op 5 continenten</p>
            </div>
            <div class="col-span-4 md:col-span-1">
                <IconLocal></IconLocal>
                <p>40 lokale afdelingen in Vlaanderen</p>
            </div>
            <div class="col-span-4 md:col-span-1">
                <Icon100years></Icon100years>
                <p>100 jaar van impact sinds 1915</p>
            </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DitIsJCI
